import cogoToast from 'cogo-toast';
import { useEffect, useState } from 'react';

//wrap notification lib for to be easy replacable

const defaultOptions = {
	position: 'top-right',
	hideAfter: 4,
	bar: {
		size: '2px',
		style: 'solid',
	},
};

const DelayedExtraMessage = ({ text, extraText, delay = 5 }) => {
	const [displayedText, setDisplayedText] = useState(text);

	useEffect(() => {
		const timerId = setTimeout(() => {
			setDisplayedText(extraText);
		}, delay * 1000);

		return () => {
			clearTimeout(timerId);
		};
	}, [extraText, setDisplayedText]);

	return <>{displayedText}</>;
};

export default class Notifications {
	static getOpts(opts = {}) {
		return {
			...defaultOptions,
			...opts,
		};
	}

	static success(msg, title = '') {
		return cogoToast.success(msg, this.getOpts({ heading: title }));
	}

	static error(msg = '', title = '') {
		const _msg = msg == '' ? 'An error occurred. Please try again.' : msg;
		return cogoToast.error(
			_msg,
			this.getOpts({ heading: title, hideAfter: 10 })
		);
	}

	static info(msg, title = '', hideAfter = 10) {
		return cogoToast.info(msg, this.getOpts({ heading: title, hideAfter }));
	}

	static warning(msg, title = '') {
		return cogoToast.warn(msg, this.getOpts({ heading: title }));
	}

	static loading(msg, title = '') {
		let n;
		let isHidden = false;
		let resolve;

		const p = new Promise((resolve_) => {
			resolve = resolve_;
		});

		p.hide = () => {
			isHidden = true;

			resolve && resolve();

			n?.hide();
		};

		setTimeout(() => {
			if (isHidden) return;

			n = cogoToast.loading(
				msg,
				this.getOpts({ heading: title, hideAfter: 0 })
			);

			n.then(() => {
				resolve && resolve();
			});
		}, 1000);

		return p;
	}

	static loadingWithDelayedMessage(msg, extraMsg, title = '') {
		return Notifications.loading(
			<DelayedExtraMessage text={msg} extraText={extraMsg} />,
			title
		);
	}
}
