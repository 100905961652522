import dynamic from "next/dynamic";
const Tabs = dynamic(import("react-tabs").then(mod => mod.Tabs), { ssr: false })

const PeteTabs = ({
	children,
	compact = false,
	wrap = false,
	...rest
}) => {
	return (
		<Tabs {...rest}>
			{children}
			<style jsx global>{`
				@import 'scss/base';
				.react-tabs {
					-webkit-tap-highlight-color: transparent;
					display: flex;
					flex-flow: column nowrap;
					flex: 1;

					&__tab-list {
						margin: 0;
						padding: 0;
						margin-bottom: 3px;
						display: flex;
						flex-flow: row nowrap;
						${wrap && ('flex-wrap: wrap')}
					}

					&__tab {
						user-select: none;
						display: block;
						padding: 0.2rem ${compact ? '0.25' : '1'}rem;
						font-weight: 500;
						font-size: 0.75rem;
						cursor: pointer;
						border-bottom: 2px solid rgba($primary, 0);
						color: $text-light;
						text-transform: uppercase;
						@include anim((color, border));
						letter-spacing: 0;

						&:active,
						&:focus {
							outline: 0 none;
						}

						&:hover {
							color: $text-muted;
						}

						&--selected {
							border-bottom: 2px solid rgba($primary, 1);
							color: black;
						}

						&--disabled {
							color: $text-light;
							cursor: default;
						}
					}

					&__tab-panel {
						display: none;
						position: relative;

						&--selected {
							display: flex;
							flex: 1;
							background: #fff;
						}
					}
				}
			`}</style>
		</Tabs>
	);
};

export default PeteTabs;
